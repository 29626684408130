import { qs, qsAll } from '../dom-helpers';

class Modals {
  constructor() {
    if (!qs('.modal')) return;

    this.openModalButton = qs('.button--modal');
    this.closeButtons = qsAll('.close');
    this.overlay = qs('.overlay');
    this.modal = qs('.modal');
    this.openSubscribeButton = qs('.footer__subscribe-button');
    this.modalSubscribe = qs('.modal__dialog--subscribe');
    this.modalContact = qs('.modal__dialog--contact');

    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    if (this.openModalButton) {
      this.openModalButton.addEventListener('click', () => this._handleOpenContactModal());
    }

    if (this.openSubscribeButton) {
      this.openSubscribeButton.addEventListener('click', () => this._handleOpenSubscribeModal());
    }

    this.closeButtons.forEach((button) => {
      button.addEventListener('click', () => this._handleCloseModal());
    });

    document.addEventListener('click', (e) => {
      const target = e.target;
      if (target.classList.contains('modal')) {
        this._handleCloseModal();
      }
    });

    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this._handleCloseModal();
      }
    });
  }

  _handleOpenContactModal() {
    this.overlay.classList.add('is-visible');
    this.modal.classList.add('is-visible');
    this.modalContact.classList.add('is-visible');
  }

  _handleOpenSubscribeModal() {
    this.overlay.classList.add('is-visible');
    this.modal.classList.add('is-visible');
    this.modalSubscribe.classList.add('is-visible');
  }

  _handleCloseModal() {
    this.overlay.classList.remove('is-visible');
    this.modal.classList.remove('is-visible');
    this.modalContact.classList.remove('is-visible');
    this.modalSubscribe.classList.remove('is-visible');
  }
}

export default Modals;
