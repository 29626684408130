import { tns } from 'tiny-slider';

import { qs } from '../dom-helpers';

class PlatformsSlider {
  constructor() {
    if (!qs('.partners-slider')) return;
    this._run();
  }

  _run() {
    try {
      this._registerSlider();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerSlider() {
    tns({
      container: '.partners-slider',
      items: 3,
      speed: 300,
      autoplay: true,
      autoplayHoverPause: true,
      controls: false,
      nav: false,
      autoplayButton: false,
      autoplayTimeout: 3000,
      gutter: 40,
      mouseDrag: true,
      swipeAngle: false,
      responsive: {
        720: {
          items: 4,
          gutter: 75,
        },
      },
    });
  }
}

export default PlatformsSlider;
